@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #fadb0f;
}

@font-face {
  font-family: "Festivo";
  src: url("./assets/fonts/Festivo.otf");
}

@layer base {
  *,
  *::after,
  *::before {
    font-family: "EB Garamond", serif;
  }
}

@layer utilities {
  .festivo {
    font-family: "Festivo", sans-serif;
  }

  .text-stripes {
    background: repeating-linear-gradient(
      -65deg,
      #eab100,
      #eab100 2px,
      #1d1d1b 2px,
      #1d1d1b 5px
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-stripes-tight {
    background: repeating-linear-gradient(
      -65deg,
      #eab100,
      #eab100 1px,
      #1d1d1b 1px,
      #1d1d1b 3px
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .stripes-dark {
    background: repeating-linear-gradient(
      -65deg,
      #eab100,
      #eab100 3px,
      #fadb0f 3px,
      #fadb0f 6px
    );
  }

  .stripes-light {
    background: repeating-linear-gradient(
      -65deg,
      #fff,
      #fff 3px,
      #fadb0f 3px,
      #fadb0f 6px
    );
  }

  .festivo {
    font-family: "Festivo";
  }

  .letter-spacing{
    letter-spacing: -1.8px;
  }
  .letter-spacing-xl{
    letter-spacing: -2.2px;
  }

  .text-22 {
    font-size: 22px;
  }

  .text-3xl {
    font-size: 26px;
  }
  .text-4xl {
    font-size: 30px;
    line-height: 32px;
  }

  .text-45xl{
    font-size: 42px;
    line-height: 36px;
  }

  .w-25{
    width: 6.5rem;
  }
  .h-25{
    height: 6.5rem;
  }
  
  @responsive{
    .right-20p{
      right: 20%;
    }
  }


  .text-44 {
    font-size: 44px;
  }

  .checkbox:checked + label div {
    background: black;
  }

  .rounded-8{
    border-top-left-radius: 8%;
    border-top-right-radius: 8%;
  }

  @media (min-width: 500px){
    .rounded-8{
      border-top-left-radius: 5%;
      border-top-right-radius: 5%;
    }
  }
}
